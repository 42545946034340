import { useCallback, useEffect, useRef, useState } from 'react'

const usePopUp = () => {
  const [popupData, setPopupData] = useState(null)
  const popupRef = useRef(null)

  useEffect(() => {
    const handleMessage = event => {
      if (event.origin === window.origin && event.data?.type === 'FROM_CHILD') {
        try {
          // eslint-disable-next-line
          for (const [key, value] of Object.entries(event.data.data)) {
            localStorage.setItem(key, value)
          }
          setPopupData(event.data.data)
        } catch (error) {
          console.error('🚀 ~External login error:', error)
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const openPopup = useCallback(params => {
    // Open a new popup window
    const popup = window.open(
      params.url, // Path to the popup component
      'popupWindow',
      `width=${params.width},height=${params.height}`
    )

    if (!popup) {
      alert('Popup blocked by browser. Please allow popups for this site.')
      return
    }

    popupRef.current = popup
  }, [])

  return { popupData, openPopup }
}

export default usePopUp
