import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
// import dayjs from 'dayjs'
// Material UI
import { CardActions, Checkbox, Fade, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import csc from 'countries-states-cities'
import { hideLoading, showLoading } from 'utils/functions'

// Assets
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
// import poweredByRelish from 'assets/img/POC/powered-by-relish.png'
import dunsNBradstreetLogo from 'assets/img/POC/poweredByRelishNDnBV2.png'
import Accordion from 'components/Accordion2/Accordion2.js'
// Components
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardText from 'components/Card/CardText'
import CustomInput from 'components/CustomInput/CustomInput.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import InfoModal from 'components/Invoice/InfoModal'
import SyncLoader from 'components/SyncLoader/SyncLoader'

// Services
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { dnbDaBypassSupplier, dnbDaGetSupplier, dnbDaMatchSupplier, dnbDaSearchCompany } from 'services/apiDnbConnector'
import Snackbar from 'components/Snackbar/Snackbar.js'
import AddAlert from '@material-ui/icons/AddAlert'
import { SupplierStatus } from '../../../utils/Constants'

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: '#FFFFFF',
    marginTop: '0'
  },
  cardCategory: {
    color: '#999999',
    marginTop: '10px'
  },
  cardCategoryWhite: {
    margin: '0',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '.875rem'
  }
}

const useStyles = makeStyles(styles)

export default function DnbDaSearchApp(props) {
  console.log('DnbDaSearchApp')
  const classes = useStyles()
  const { supplierId, appId } = useParams()

  const hideAlert = () => {
    setState(old => ({
      ...old,
      alert: null
    }))
  }

  const mapCountries = () => {
    return csc.getAllCountries().map(country => (
      <MenuItem key={country.iso2} value={country.iso2}>
        {country.name}
      </MenuItem>
    ))
  }

  const [state, setState] = useState({
    alert: null,
    companyInformation: null,
    code: '',
    message: '',
    error: null,
    matchCandidates: null,
    matchDuns: null,
    searchCriteria: null,
    showLoader: true,
    snackBarMessage: null,
    snackBarShowError: false,
    snackBarShowSuccess: false,
    supplierName: ''
  })

  const setMatchCriteria = (name, value) => {
    setState(old => ({
      ...old,
      searchCriteria: {
        ...old.searchCriteria,
        [name]: value
      }
    }))
  }

  const { isLoading: loadingDnbDaGetSupplier, isFetching: fetchingDnbDaGetSupplier } = useQuery(
    ['dnbDaGetSupplier', 'dnbConnector-dnbDaGetSupplier'],
    () =>
      dnbDaGetSupplier(supplierId, appId).then(async result => {
        if (result.success) {
          return result.data
        } else {
          throw result
        }
      }),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      onSuccess: data => {
        if (data) {
          setState(old => ({
            ...old,
            ...data
          }))
        }
      },
      onError: result => {
        setState(old => ({
          ...old,
          error: `${result.status}: ${result.error}`
        }))
      }
    }
  )

  const { refetch: refetchDnbDaSearchCompany } = useQuery(
    ['dnbDaSearchCompany', `dnbConnector-dnbDaSearchCompany`],
    () => dnbDaSearchCompany(appId, state.searchCriteria).then(result => result.data),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      onSuccess: data => {
        hideLoading()
        if (data) {
          setState(old => ({
            ...old,
            ...data
          }))
        }
      }
    }
  )

  const { refetch: refetchDnbDaBypassSupplier } = useQuery(
    ['dnbDaBypassSupplier', `dnbConnector-dnbDaBypassSupplier`],
    () => dnbDaBypassSupplier(appId, supplierId).then(result => result.data),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      onSuccess: data => {
        hideLoading()
        if (data) {
          setState(old => ({
            ...old,
            message: data.message,
            code: data.code,
            snackBarShowSuccess: true,
            snackBarMessage: data.message
          }))
          console.log(state)
        }
      }
    }
  )

  const { refetch: refetchDnbDaMatchSupplier } = useQuery(
    ['dnbDaMatchSupplier', `dnbConnector-dnbDaMatchSupplier`],
    () => dnbDaMatchSupplier(appId, supplierId, state.matchDuns).then(result => result.data),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      onSuccess: data => {
        hideLoading()
        setState(old => ({
          ...old,
          message: data.message,
          code: data.code,
          snackBarShowSuccess: true,
          snackBarMessage: 'The supplier has been successfully matched.'
        }))
      }
    }
  )

  useEffect(() => {
    setState(old => ({
      ...old,
      showLoader: loadingDnbDaGetSupplier || fetchingDnbDaGetSupplier
    }))
  }, [loadingDnbDaGetSupplier, fetchingDnbDaGetSupplier])

  return (
    <>
      <GridContainer
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '30px',
          paddingBottom: '30px',
          width: '100%',
          margin: '0'
        }}
      >
        <GridItem xs={12}></GridItem>
        {(state.showLoader && <SyncLoader showLoader={true} />) ||
          (state.error && <h1>{state.error}</h1>) ||
          (state.code !== SupplierStatus.SUPPLIER_NOT_MATCHED && <h1>{state.message}</h1>) || (
            <>
              {state.alert}

              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardHeader>
                    <CardText style={{ backgroundColor: '#3C78C8' }}>
                      <h4 className={classes.cardTitleWhite}>{state.supplierName}</h4>
                      {!state.internalParameters?.dnbValidation?.candidatesSearchOnly && (
                        <h5 className={classes.cardCategoryWhite}>{`${state.matchCandidates?.length} possible matches`}</h5>
                      )}
                      {state.internalParameters?.dnbValidation?.candidatesSearchOnly && (
                        <h4 className={classes.cardCategoryWhite}>{`Suppliers Search`}</h4>
                      )}
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    <p style={{ textAlign: 'left' }}>
                      {!state.internalParameters?.dnbValidation?.candidatesSearchOnly && (
                        <b>Please select a company to associate information with DnB Connector</b>
                      )}
                      <Box textAlign="right">
                        <b>If you want to bypass this supplier, click the button</b>
                        <br></br>
                        <Button
                          color="primary"
                          type="button"
                          disabled={false}
                          variant="contained"
                          style={{
                            marginRight: '5px',
                            backgroundColor: '#081c3e'
                          }}
                          onClick={() => {
                            showLoading()
                            refetchDnbDaBypassSupplier({ throwOnError: true })
                          }}
                        >
                          {'Bypass Supplier'}
                        </Button>
                      </Box>
                      <Accordion
                        collapses={[
                          {
                            title: 'Match Criteria',
                            content: (
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                  <GridContainer>
                                    <GridItem xs={12} sm={6} md={4}>
                                      <CustomInput
                                        labelText="DUNS Number"
                                        id="dunsNumber"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria('duns', event.target.value)
                                          },
                                          type: 'text',
                                          value: state.searchCriteria.duns
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                      <CustomInput
                                        labelText="Company Name"
                                        id="companyName"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria('companyName', event.target.value)
                                          },
                                          type: 'text',
                                          value: state.searchCriteria.companyName
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <FormControl fullWidth>
                                          <InputLabel id="countryLabel">Country</InputLabel>
                                          <Select
                                            labelId="countryLabel"
                                            id="countrySelect"
                                            value={state.searchCriteria.countryISOAlpha2Code}
                                            onChange={event => {
                                              setMatchCriteria('countryISOAlpha2Code', event.target.value)
                                            }}
                                          >
                                            {mapCountries()}
                                          </Select>
                                        </FormControl>
                                      </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <CustomInput
                                        labelText="Address Line 1"
                                        id="addressLine1"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria('streetAddressLine1', event.target.value)
                                          },
                                          type: 'text',
                                          value: state.searchCriteria.streetAddressLine1
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <CustomInput
                                        labelText="Address Line 2"
                                        id="addressLine2"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria('streetAddressLine2', event.target.value)
                                          },
                                          type: 'text',
                                          value: state.searchCriteria.streetAddressLine2
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                      <CustomInput
                                        labelText="Postal Code"
                                        id="postalCode"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria('postalCode', event.target.value)
                                          },
                                          type: 'text',
                                          value: state.searchCriteria.postalCode
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                      <CustomInput
                                        labelText="Region"
                                        id="region"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria('addressRegion', event.target.value)
                                          },
                                          type: 'text',
                                          value: state.searchCriteria.addressRegion
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                      <CustomInput
                                        labelText="Registration Code"
                                        id="registrationCode"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria('registrationCode', event.target.value)
                                          },
                                          type: 'text',
                                          value: state.searchCriteria.registrationCode
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                      <CustomInput
                                        labelText="URL Domain"
                                        id="url"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria('url', event.target.value)
                                          },
                                          type: 'text',
                                          value: state.searchCriteria.url
                                        }}
                                      />
                                    </GridItem>
                                  </GridContainer>
                                  <GridContainer>
                                    {state.searchCriteria.exclusions &&
                                      state.searchCriteria.exclusions.map(exclusion => {
                                        return (
                                          <GridItem xs={4}>
                                            <FormControlLabel
                                              key={exclusion.exclusionName}
                                              disabled={!state.internalParameters?.dnbValidation?.candidatesSearchOnly}
                                              control={<Checkbox checked={exclusion.exclusionValue} />}
                                              label={exclusion.exclusionLabel}
                                              onChange={event => {
                                                exclusion.exclusionValue = event.target.checked
                                                setMatchCriteria('exclusions', state.searchCriteria.exclusions)
                                              }}
                                            />
                                          </GridItem>
                                        )
                                      })}
                                    <GridItem xs={12} sm={12} md={12}>
                                      <Box textAlign="right">
                                        <Button
                                          color="primary"
                                          type="button"
                                          disabled={false}
                                          variant="contained"
                                          style={{
                                            marginRight: '5px',
                                            backgroundColor: '#081c3e'
                                          }}
                                          onClick={() => {
                                            showLoading()
                                            refetchDnbDaSearchCompany({ throwOnError: true })
                                          }}
                                        >
                                          {'Search'}
                                        </Button>
                                      </Box>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                              </GridContainer>
                            )
                          }
                        ]}
                      />
                    </p>
                    <MaterialTable
                      columns={[
                        {
                          cellStyle: { padding: '5px 16px' },
                          title: 'Company Name',
                          field: 'primaryName'
                        },
                        {
                          cellStyle: { padding: '5px 16px' },
                          title: 'DUNS number',
                          field: 'duns'
                        },
                        {
                          cellStyle: { padding: '5px 16px' },
                          title: 'Confidence Code',
                          field: 'matchScore'
                        },
                        {
                          cellStyle: { padding: '5px 16px' },
                          title: 'Address',
                          field: 'address'
                        },
                        {
                          cellStyle: { padding: '5px 16px' },
                          title: 'Country',
                          field: 'country'
                        },
                        {
                          cellStyle: { padding: '5px 16px' },
                          title: 'Region',
                          field: 'region'
                        },
                        {
                          cellStyle: { padding: '5px 16px' },
                          title: 'Postal Code',
                          field: 'postalCode'
                        },
                        {
                          cellStyle: { padding: '5px 16px' },
                          title: 'Registration Codes',
                          field: 'registrationNumbers',
                          render: rowData =>
                            (rowData.registrationNumbers.length === 1 && (
                              <p>{`${rowData.registrationNumbers[0].registrationNumber} - ${rowData.registrationNumbers[0].typeDescription}`}</p>
                            )) ||
                            (rowData.registrationNumbers.length > 1 && (
                              <IconButton
                                onClick={() => {
                                  setState(old => ({
                                    ...old,
                                    alert: (
                                      <ModalWrapper>
                                        <InfoModal
                                          fullWidth={true}
                                          maxWidth="sm"
                                          showModal={true}
                                          warningMessage={
                                            <>
                                              {rowData.registrationNumbers.map(e => {
                                                return <p>{`${e.registrationNumber} - ${e.typeDescription}`}</p>
                                              })}
                                            </>
                                          }
                                          title={'Registration Codes'}
                                          textSuccessBtn={'Accept'}
                                          onSubmit={async () => {
                                            hideAlert()
                                          }}
                                          onClose={() => hideAlert()}
                                          transitionComponent={Fade}
                                        />
                                      </ModalWrapper>
                                    )
                                  }))
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            ))
                        },
                        {
                          hidden: state.internalParameters?.dnbValidation?.candidatesSearchOnly,
                          cellStyle: { padding: '5px 16px' },
                          title: 'Match',
                          render: rowData => (
                            <IconButton
                              onClick={() => {
                                setState(old => ({
                                  ...old,
                                  matchDuns: rowData.duns,
                                  alert: (
                                    <ModalWrapper>
                                      <InfoModal
                                        fullWidth={true}
                                        maxWidth="sm"
                                        showModal={true}
                                        warningMessage={
                                          <>
                                            <p>{`Do you want to match DUNS Number ${rowData.duns} with Coupa supplier ${state.supplierName} (${supplierId})?`}</p>
                                          </>
                                        }
                                        title={'D&B Supplier Match'}
                                        textSuccessBtn={'Match'}
                                        onSubmit={async () => {
                                          hideAlert()
                                          showLoading()
                                          refetchDnbDaMatchSupplier()
                                        }}
                                        onClose={() => hideAlert()}
                                        transitionComponent={Fade}
                                      />
                                    </ModalWrapper>
                                  )
                                }))
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          )
                        }
                      ]}
                      data={state.matchCandidates}
                      options={{
                        actionsColumnIndex: -1,
                        sorting: true,
                        showTitle: false,
                        search: false,
                        paging: false,
                        toolbar: false
                      }}
                    />
                  </CardBody>
                  <CardActions>
                    <GridContainer>
                      <GridItem xs={6} style={{ textAlign: 'left' }}>
                        <a
                          href="https://relishiq.com/solutions/relish-data-assure/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#3c78c8' }}
                        >
                          <img src={dunsNBradstreetLogo} alt="Go to Relish" style={{ maxWidth: '500px' }} />
                        </a>
                      </GridItem>
                    </GridContainer>
                  </CardActions>
                </Card>
              </GridItem>
            </>
          )}
        <Snackbar
          place="br"
          color="success"
          icon={AddAlert}
          message={state.snackBarMessage || ''}
          open={state.snackBarShowSuccess}
          closeNotification={() =>
            setState(old => ({
              ...old,
              snackBarShowSuccess: false
            }))
          }
          close
        />
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={state.snackBarMessage || ''}
          open={state.snackBarShowError}
          closeNotification={() =>
            setState(old => ({
              ...old,
              snackBarShowError: false
            }))
          }
          close
        />
      </GridContainer>
    </>
  )
}
