import React from 'react'
import { useHistory } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// material ui icons

// core components
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

// images
import LoginImage from 'assets/img/LoginImage.png'

import styles from 'assets/jss/material-dashboard-pro-react/views/loginViewStyle.js'
import usePopUp from './usePopUp'

const useStyles = makeStyles(styles)

export function path(root, sublink) {
  const rootTemp = typeof window !== 'undefined' && root === '' ? window.location.origin : root
  return `${rootTemp}${sublink}`
}

export default function LoginPageExternal() {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden')
  const { openPopup, popupData } = usePopUp()
  const isLogged = Boolean(popupData)
  const history = useHistory()
  const login = path('', `/auth/login-page`)

  setTimeout(function() {
    setCardAnimation('')
  }, 700)

  React.useEffect(() => {
    localStorage.removeItem('login.method')
    localStorage.setItem('login.workflow', 'external')
  }, [])

  const classes = useStyles()

  function handleLoginButtonClick(params) {
    openPopup({
      url: login,
      width: 400,
      height: 648
    })
  }
  React.useEffect(() => {
    if (!isLogged) {
      return
    }

    localStorage.removeItem('login.workflow')
    localStorage.setItem('login.method', 'external')
    history.push(`/admin/home`)
    // eslint-disable-next-line
  }, [isLogged])

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="primary">
                <h2 className={classes.cardTitle}>Relish</h2>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={12}>
                  <img width="100%" src={LoginImage} alt="logo" className={classes.logotypeImage} />
                </GridItem>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="primary" simple size="lg" block onClick={handleLoginButtonClick}>
                  Login Pop Up
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  )
}
